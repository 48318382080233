<template>
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2020 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="font-bold mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Australia CSIRO</li>
              <li>Canadian Explosives Research Laboratory</li>
              <li>
                Federal Institute for Materials Research and Testing (BAM)
              </li>
              <li>Health &amp; Safety Laboratory (HSL)</li>
              <li>INERIS</li>
              <li>Innovative Materials &amp; Processes, LLC</li>
              <li>Department of Homeland Security</li>
              <li>Federal Bureau of Investigation (FBI)</li>
              <li>Lawrence Livermore National Laboratory</li>
              <li>Los Alamos National Laboratory</li>
              <li>Missouri University of Science and Technology</li>
              <li>Naval Research Laboratory</li>
              <li>
                Orbital ATK
                <ul class="list-disc p-light ml-8">
                  <li>ABL</li>
                  <li>Corporate</li>
                  <li>Bacchus</li>
                  <li>Promontory</li>
                  <li>Lake City</li>
                </ul>
              </li>
              <li>Safety Management Services, Inc.</li>
              <li>Tooele Army Depot</li>
              <li>
                Sporting Arms and Ammunition Manufacturers’ Institute (DG
                Advisor, LLC)
              </li>
              <li>Sandia National Laboratory</li>
              <li>Signature Science, LLC</li>
              <li>U.S. Army RDECOM – ARDEC – Picatinny Arsenal</li>
              <li>Vista Outdoor – CCI/Speer</li>
              <li>Vista Outdoor – Federal Premium Ammunition</li>
            </ul>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2020 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2020%2F001%20Standardization%20and%20the%20ET%20Users%20Group%20Charter.pdf?alt=media&token=93101b19-c456-48a7-8c21-872a392be0c7"
                        >Standardization and the ET Users Group Charter</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2020%2F002%20SMS%20Koenen%20Round%20Robin%20%20Results.pdf?alt=media&token=739cd2a9-9273-48bf-96e6-372475128179"
                        >Koenen Round Robin Results – SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2020%2F003%20SANDIA%20Koenen%20Round%20Robin%20Results.pdf?alt=media&token=9d733345-0724-4cea-837b-e35d38b62daa"
                        >Koenen Round Robin Results – Sandia</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2020%2F004%20Number%208%20Detonator%20to%20Simulate%20In-Process%20Shock%20Stimulus.pdf?alt=media&token=c28026b3-a48d-4a7e-90aa-e9a67b9f2193"
                        >Number 8 Detonator to Simulate In-Process Shock
                        Stimulus</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2020%2F005%20In-Process%20Characterization%20Example%20with%20Propellant%20Cutting.pdf?alt=media&token=c4d1ab5f-f1af-42f9-9765-df5cd13e73b9"
                        >In-Process Characterization Example with Propellant
                        Cutting</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2020%2F006%20Sensitivity%20Reaction%20Detection%20with%20GoDetect%20ESD%20Version%208.pdf?alt=media&token=f160202b-67db-4546-abc8-158c7dfae289"
                        >Sensitivity Reaction Detection with GoDetect ESD
                        version 8</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2020%2F007%202020%20History%20of%20NGC%20Autoliv%20and%20Dyno%20Nobel.pdf?alt=media&token=7e2d491f-4999-4c18-a38c-fa180ba38068"
                        >Rocket Motors, Airbags & Explosives in Utah through
                        History of NGC, Autoliv and Dyno Nobel</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2020%2F008%20General%20Meeting%20Discussion%20and%20Wrap-up.pdf?alt=media&token=19856e8a-3588-4b5e-b9ab-fc467381e738"
                        >General Meeting Discussion and Wrap-up</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
